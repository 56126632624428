/*
  BRAND LEVEL THEME
  If a project has multiple sites that have shared theming, those styles can be placed here.
  If a site needs to override a brand level style, that can be done by writing the desired override in the site level theme.js file.
  This brand theme will get imported into the site theme and the two will be merged using lodash.merge.
*/
module.exports = {
  colors: {
    primary: '#3773b6',
    secondary: '#112338',
    tertiary: '#c19e3a',
    dark: '#1d2329',
    light: '#f2f2f2',
    warning: '#ff7928',
    error: '#ff3068',
    success: '#2de6bc',
    background1: '#E7F2FD',
    background2: '#C4DDFA',
    background3: '#89BBF3',
    background4: '#F9F5EC',
    background5: '#E6D8B5',
    background6: '#DAC58F',
  },
  gradients: {
    primary: 'linear-gradient(to right, #0079b1, #112338, #00c9ff)',
    secondary: 'linear-gradient(to right, #3773b6, #C19E3a)',
    tertiary: 'linear-gradient(to right, #ff585d, #ffb000)',
  },
  alternateBgColor: true,
  fonts: {
    headings: {
      family: "'ATT Aleck Sans', sans-serif",
    },
    subheadings: {
      family: "'ATT Aleck Sans', sans-serif",
    },
    body: {
      family: "'ATT Aleck Sans', sans-serif",
    },
  },
  components: {
    Button: {
      rounded: 'min',
    },
  },
  shadow: '0 0.3rem 1rem 0 rgba(0, 0, 0, 0.1)',
  radius: '4px',
}
