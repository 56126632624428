module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.business-providers.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-54J4NB4","brandTokens":["TEL"],"mapiBrandToken":"TEL","siteName":"business-providers","alternateName":"business-providers.com","siteURL":"https://www.business-providers.com","defaultTitleTemplate":"","defaultPhone":"8336110579","phoneSymbol":"-","defaultPromoCode":"155246","removeTrailingSlashes":true,"smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10046086","vwoProjectID":"894940"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"business-providers","short_name":"business-providers","start_url":"/","background_color":"#1990FF","theme_color":"#1990FF","icon":"src/images/business-providers.svg","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0bd3f7d83c6aa3fb3376b4f9db42ad4d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
